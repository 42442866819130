<template>
  <div :class="payload.customClass">
    <template v-for="i in payload.content">
      <NuxtLinkLocale :to="i.ctaLink ? checkAndAddSlash(i.ctaLink) : '#'" class="rounded-lg overflow-hidden" :class="i.customClass">
        <div class="relative h-full group">
          <div class="absolute z-30 flex inset-0">
            <div
              :class="[
              'w-full z-30 px-6 py-6 text-white flex space-y-2 tracking-wider',
              horizontalClass(i.config.titleHPlacement),
              verticalClass(i.config.titleVPlacement),
              {[backdropClass] : payload.config?.text_backdrop_enable}
          ]">
              <span :class="titleClass">{{ i.title }}</span>
              <span :class="subTitleClass">{{ i.subTitle }}</span>
            </div>
          </div>
          <NuxtImg
              :class="[i.config?.imgClass, 'z-0']"
              :src="getImageForDevice(i)"
              :alt="i.title"
              placeholder
              :quality="65"
              sizes="xs:256px md:512px lg:1024"
              fit="cover"
              format="webp"
              loading="lazy"
              class="min-h-full min-w-full object-cover"
          />
        </div>
      </NuxtLinkLocale>
    </template>
  </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps(['payload'])
import {horizontalClass, verticalClass} from "~/composables/useHelper";

const backdropClass = computed(() => props.payload.config?.text_backdrop_class ? props.payload.config?.text_backdrop_class : 'bg-gradient-to-t from-black/40 via-transparent to-transparent')
const titleClass = computed(() => props.payload.config?.title_class ? props.payload.config?.title_class : 'text-3xl font-medium')
const subTitleClass = computed(() => props.payload.config?.sub_title_class ? props.payload.config?.sub_title_class : 'text-xl')

</script>